
import { defineComponent } from "vue";
import { db, auth } from "@/main";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";

export default defineComponent({
  methods: {
    async loadUser(){
      if(auth.currentUser){
        this.$store.commit("setUser", auth.currentUser);
      }
    },
    async getPlaces() {
      const querySnapshot = await getDocs(collection(db, "places"));
      let places = [] as any;
      querySnapshot.forEach((doc) => {
        let temp = doc.data();
        temp.id = doc.id;
        places.push(temp);
      });

      //sort places by index property
      places.sort((a: any, b: any) => {
        return a.index - b.index;
      });

      //add to vuex
      if (places.length > 0) {
        this.$store.commit("loadPlacesFromCSV", places);

      }
    },
  },
  computed: {},
  async mounted() {
    this.getPlaces();
    this.loadUser();
  },
  unmounted() {},
  setup() {
    return {};
  },
});
