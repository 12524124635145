
import { defineComponent } from "vue";
import { db } from "@/main";
import { setDoc, collection, doc } from "@firebase/firestore";
export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    complete: {
      type: Boolean,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
    notes: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    boxes: {
      type: Number,
      required: false,
    },
    time: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    async toggleComplete() {
      this.$store.commit("toggleComplete", this.index);
      //update firestore field
      const docRef = doc(db, "places", this.id.toString());
      await setDoc(docRef, { complete: !this.complete }, { merge: true });
    },
  },
  computed: {
    statusTextColor() {
      switch (this.complete as Boolean) {
        case true:
          return "#5ACC97";
        case false:
          return "#F56B79";
        default:
          return "#F9FBFC";
      }
    },
    statusBgColor() {
      switch (this.complete as Boolean) {
        case true:
          return "#DEF3E9";
        case false:
          return "#F8D9DD";
        default:
          return "#7C7C7E";
      }
    },
    status() {
      switch (this.complete as Boolean) {
        case true:
          return "Complete";
        case false:
          return "Incomplete";
        default:
          return "Unknown";
      }
    },
    map_link() {
      return `https://www.google.com/maps/search/?api=1&query=${this.address}`;
    },
  },
});
