import { createStore } from "vuex";
export type State = {
  places: Place[];
  user: any;
};
export type Place = {
  name: string;
  address: string;
  notes: string;
  boxes: number;
  complete: boolean;
  index: number;
  id: string;
};

export default createStore({
  state: {
    places: [] as Place[],
    user: null as any,
  },
  getters: {},
  mutations: {
    toggleComplete(state, index) {
      state.places[index].complete = !state.places[index].complete;
    },

    loadPlacesFromCSV(state, places) {
      //clear the array
      state.places = [];

      state.places = places;
    },

    addPlace(state, place) {
      console.log("addPlace");
      state.places.push(place);
    },
    setUser(state, user) {
      state.user = user;
    }

  },
  actions: {
    toggleComplete({ commit }, index) {
      commit("toggleComplete", index);
    },
    loadPlacesFromCSV({ commit }, places) {
      commit("loadPlacesFromCSV", places);
    },
    addPlace({ commit }, place) {
      commit("addPlace", place);
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    }

  },
  modules: {},
});
