
import { defineComponent } from "vue";
import PlaceCard from "@/components/place-card.vue";
import { db, auth } from "@/main";
import { addDoc, collection } from "@firebase/firestore";

import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

export default defineComponent({
  name: "HomeView",
  components: { PlaceCard },
  data() {
    return {
      completedPlaces: 0,
      isAddingPlaces: false,
      file: null as any,
      fields: {
        name: {
          required: true,
          label: "Name",
        },
        notes: {
          required: true,
          label: "Notes",
        },
        address: {
          required: true,
          label: "Address",
        },
        boxes: {
          required: false,
          label: "Boxes",
        },
        time: {
          required: false,
          label: "Time",
        },
      },
    };
  },
  methods: {
    async signInWithGoogle() {
      /*  if (this.$store.state.user) {
        console.log("already signed in");
        return;
      } */
      const provider = new GoogleAuthProvider();
      try {
        let result = await signInWithPopup(auth, provider);
        this.$store.commit("setUser", result.user);
      } catch (error) {
        console.log(error);
      }
    },
    toggleAddingPlaces() {
      this.isAddingPlaces = !this.isAddingPlaces;
    },
    async loadPlacesFromCSV() {
      console.log("loadPlacesFromCSV");
      if (!this.file) {
        console.log("no file");
        console.log(this.file);

        return;
      }

      console.log("file", this.file);

      this.file.forEach(async (place: any, index: number) => {
        if (index !== 0) {
          this.$store.commit("addPlace", {
            name: place.name,
            address: place.address,
            notes: place.notes,
            boxes: parseInt(place.boxes),
            complete: false,
            time: place.time,
            index: index,
          });

          await addDoc(collection(db, `places`), {
            name: place.name,
            address: place.address,
            notes: place.notes,
            boxes: parseInt(place.boxes),
            time: place.time,
            complete: false,
            index: index,
          });
        }
      });

      this.isAddingPlaces = false;
    },
  },
  computed: {
    username(): string {
      if (this.$store.state.user && this.$store.state.user.displayName) {
        return `Welcome, ${this.$store.state.user.displayName}`;
      } else {
        return "Sign In";
      }
    },
    allPlaces(): any {
      return this.$store.state.places;
    },

    progress(): number {
      //return the percentage of completed places
      let completed = 0;
      this.allPlaces.forEach((place: any) => {
        if (place.complete) {
          completed++;
        }
      });
      this.completedPlaces = completed;
      return Math.round((completed / this.allPlaces.length) * 100);
    },
    displayAddModal(): boolean {
      if (!this.$store.state.places) {
        return true;
      } else if (this.$store.state.places && this.isAddingPlaces) {
        return true;
      } else {
        return false;
      }
    },
  },
});
